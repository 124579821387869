<template>
  <div>
    <dashboard-datepicker
      :date-start="dateStart"
      :date-end="dateEnd"
      :date-tenant-created="dateTenantCreated"
      v-on:date-changed="refreshData" />
    <div class="flex flex-col xl:flex-row xl:space-x-4 max-xl:space-y-4">      
      <dashboard-info title="Revenue"
                      title-icon="circle-dollar"
                      :amount="revenue"
                      :amount-prev="revenuepp"
                      :data-points="revenueDataPoints"
                      :percent="revenuePercent"
                      :is-highlighted="true"
                      :is-money="true"
                      :key="revenueKey" />

      <dashboard-info title="New Subscribers"
                      title-icon="user-plus"
                      :amount="subCount"
                      :amount-prev="subCountpp"
                      :data-points="subDataPoints"
                      :percent="subPercent"
                      :is-highlighted="false"
                      :is-money="false"
                      :key="subscribersKey" />    

      <dashboard-info title="MRR Growth"
                      title-icon="chart-line"
                      :amount="mrrGrowth"
                      :amount-prev="mrrGrowthPp"
                      :data-points="mrrGrowthDataPoints"
                      :percent="mrrGrowthPercent"
                      :is-highlighted="false"
                      :is-money="true"
                      :key="mrrGrowthKey" />
    </div>
    <dashboard-activity :visitor-count="visits"
                        :view-count="pageviews"
                        :percent-visits="visitsPercent"
                        :percent-views="pageviewsPercent"
                        :data-points-views="pageviewsDataPoints"
                        :data-points-visits="visitsDataPoints"
                        :key="activityKey "/>
    <div class="flex flex-col xl:flex-row xl:space-x-4 max-xl:space-y-4">
      <dashboard-videos :videos="topVideos"
                        :key="topVideosKey" />
      <dashboard-usage :hours-viewed="hoursViewed"
                       :hours-viewed-period="hoursViewedPeriod"
                       :plan-max-hours="planMaxHours"
                       :renews="renews"
                       :key="usageKey "/>
        
    </div>
    
  </div>
</template>

<script>
import DashboardActivity from './dashboard_activity.vue'
import DashboardDatepicker from './dashboard_datepicker.vue'
import DashboardInfo from './dashboard_info.vue'
import DashboardUsage from './dashboard_usage.vue'
import DashboardVideos from './dashboard_videos.vue'


export default {
  props: {
    initDateStart: String,
    initDateEnd: String,
    dateTenantCreated: String,

    initRevenue: String,
    initRevenueDataPoints: Array,
    initRevenuepp: String,
    initRevenuePercent: Number,

    initSubCount: String,
    initSubCountpp: String,
    initSubPercent: Number,
    initSubDataPoints: Array,
    
    initMrrGrowth: String,
    initMrrGrowthDataPoints: Array,
    initMrrGrowthPp: String,
    initMrrGrowthPercent: Number,

    initPageviews: String,
    initPageviewsDataPoints: Array,
    initPageviewsPercent: Number,

    initVisits: String,
    initVisitsDataPoints: Array,
    initVisitsPercent: Number,

    initTopVideos: Array,

    initHoursViewed: Number,
    initHoursViewedPeriod: Number,
    initPlanMaxHours: Number,
    initRenews: String,

    initTimezone: String
  },
  data() {
    return {
      dateStart: this.initDateStart,
      dateEnd: this.initDateEnd,
      revenue: this.initRevenue,
      revenueDataPoints: this.initRevenueDataPoints,
      revenuepp: this.initRevenuepp,
      revenuePercent: this.initRevenuePercent,
      revenueKey: 0,
      subCount: this.initSubCount,
      subCountpp: this.initSubCountpp,
      subPercent: this.initSubPercent,
      subDataPoints: this.initSubDataPoints,
      subscribersKey: 1,
      mrrGrowth: this.initMrrGrowth,
      mrrGrowthDataPoints: this.initMrrGrowthDataPoints,
      mrrGrowthPp: this.initMrrGrowthPp,
      mrrGrowthPercent: this.initMrrGrowthPercent,
      mrrGrowthKey: 2,
      pageviews: this.initPageviews,
      pageviewsDataPoints: this.initPageviewsDataPoints,
      pageviewsPercent: this.initPageviewsPercent,
      visits: this.initVisits,
      visitsDataPoints: this.initVisitsDataPoints,
      visitsPercent: this.initVisitsPercent,
      activityKey: 3,
      topVideos: this.initTopVideos,
      topVideosKey: 4,
      hoursViewed: this.initHoursViewed,
      hoursViewedPeriod: this.initHoursViewedPeriod,
      planMaxHours: this.initPlanMaxHours,
      renews: this.initRenews,
      usageKey: 5,
    }
  },
  components: {
    DashboardActivity,
    DashboardDatepicker,
    DashboardInfo,
    DashboardUsage,
    DashboardVideos,
  },
  computed: {
  },
  mounted: function () {
    let ourTz = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (this.initTimezone !== ourTz) {
      let requestParams = {
        method: 'put',
        url: `/user_profile/timezone.json`,
        data: { 'timezone': ourTz },
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
        }
      }

      this.$http(requestParams)
        .then((response) => {
          if (response.status == 200) {
            // refresh to make sure stats respect the user's timezone
            window.location('/dashboard')
          } else {
            // not sure if this scenario is worth addressing
            console.log(response.status)
          }
        }).catch((e) => {
          console.log(e)
        })
    }
  },
  methods: {
    refreshData(range) {

      // range dates are in tenant's local timezone
      const startUTC = new Date(range.start).toISOString().split('T')[0]
      const endUTC = new Date(range.end).toISOString().split('T')[0]

      let requestParams = {
        method: 'get',
        url: `/dashboard.json?date_start=${startUTC}&date_end=${endUTC}`,
      }

      this.$http(requestParams)
        .then((response) => {
          // revenue
          this.revenue = response.data.revenue
          this.revenueDataPoints = response.data.revenue_data_points
          this.revenuepp = response.data.revenuepp
          this.revenuePercent = response.data.revenue_percent
          this.revenueKey += 1 // forces child component re-render

          // subscribers
          this.subCount = response.data.sub_count.toString()
          this.subCountpp = response.data.sub_count_pp.toString()
          this.subDataPoints = response.data.sub_data_points
          this.subPercent = response.data.sub_percent
          this.subscribersKey += 1

          // mrr growth
          this.mrrGrowth = response.data.mrr_growth
          this.mrrGrowthDataPoints = response.data.mrr_growth_data_points
          this.mrrGrowthPp = response.data.mrr_growth_pp
          this.mrrGrowthPercent = response.data.mrr_growth_percent
          this.mrrGrowthKey += 1

          // activity
          this.pageviews = response.data.pageviews
          this.pageviewsDataPoints = response.data.pageviews_data_points
          this.pageviewsPercent = response.data.pageviews_percent
          this.visits = response.data.visits
          this.visitsDataPoints = response.data.visits_data_points
          this.visitsPercent = response.data.visits_percent
          this.activityKey += 1
          
          // top videos
          this.topVideos = response.data.top_videos
          this.topVideosKey += 1

          // usage
          this.hoursViewed = response.data.hours_viewed
          this.hoursViewedPeriod = response.data.hours_viewed_period
          this.planMaxHours = response.data.plan_max_hours
          this.renews = response.data.renews
          this.usageKey += 1
        }).catch((e) => {
          console.log(e)
        })
    }
  },
}
</script>

